// Trade Tariff
export const tradeTariffDasboardRoute = '/configuration/dashboard'
// E-biz Dashboard
export const eBizDasboardRoute = '/configuration/dashboard'
export const uidRoute = '/configuration/user-assign/details'
// Tcb Dashboard
export const dasbhoard = '/dashboard'
export const dasbhoardDcOffice = '/dashboard/dc-office'
export const circularsApi = '/dashboard/circulars'
export const ministryDealerDasbhoard = '/ministry/dealer-dashbaord'
// Bazar Monitoring Dashboard
export const bazarMonitoringDashboard = '/ministry/ministry-dashboard'
