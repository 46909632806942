<template>
  <div class="inner-section">
    <b-card>
      <div class="p-3">
        <b-overlay :show="loader">
          <div class="row">
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <div class="dealer-count-card">
                <b-card-text b-card-text>{{$t('manage_information.total_dealer')}}</b-card-text>
                <h4>{{$n(totalDealer)}}</h4>
                <svg class="bg-svg" viewBox="0 0 62 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M-88.6806 -57.4469C-59.6 -67.162 -25.7507 -61.2708 -1.4871 -42.5749C19.8642 -26.123 15.3615 5.3757 25.3342 30.4052C36.4531 58.3117 70.735 82.317 59.3596 110.139C47.9934 137.938 8.55538 136.837 -20.8072 143.258C-45.4979 148.658 -70.7621 154.12 -93.7344 143.62C-117.334 132.833 -133.272 111.36 -143.094 87.3635C-153.686 61.4852 -159.522 33.1227 -149.682 6.93307C-138.877 -21.8208 -117.839 -47.7059 -88.6806 -57.4469Z"
                    fill="#4B9D75" />
                </svg>
              </div>
            </b-col>
            <b-col sm="12">
              <b-card>
                <div class="text-dark">
                  <h4 class="card_title">{{ $i18n.locale === 'en' ? 'Number of dealers by region' : 'অঞ্চল ভিত্তিক ডিলার সংখ্যা' }}</h4>
                  <apexchart type="bar" height="500" :options="barOptions" :series="barSeries"></apexchart>
                  <!-- <div class="text-center">Applicant</div> -->
                </div>
              </b-card>
            </b-col>
          </div>
        </b-overlay>
      </div>
      <!-- search section end -->
    </b-card>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { ministryDealerDasbhoard } from '../../api/routes'
export default {
  data () {
    return {
      search: {
        notice_id: 0
      },
      totalDealer: 0,
      baseUrl: licenseRegistrationServiceBaseUrl,
      loader: false,
      dealerList: [],
      regionalOfficeList: [],
      show: false
    }
  },
  created () {
    this.searchData()
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    barSeries () {
        return [{
            name: this.$i18n.locale === 'bn' ? 'ডিলার সংখ্যা' : 'Total Dealer',
            data: this.dealerList
        }]
    },
    barOptions () {
        return {
            chart: {
                type: 'bar',
                height: 300
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded',
                    dataLabels: {
                      position: 'top'
                    }
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
            },
            xaxis: {
                title: {
                  text: this.$t('manage_information.regional_office')
                },
                type: 'text',
                categories: this.regionalOfficeList.map(item => this.currentLocale === 'en' ? item.text_en : item.text_bn).map(text => text.split(',').pop().trim())
            },
            yaxis: {
                title: {
                  text: this.$t('globalTrans.number')
                }
            },
            fill: {
                opacity: 1
            }
        }
      }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
        }
    }
  },
  methods: {
    async searchData () {
      this.loadData = true
      this.loader = true
      let result = null
      const params = Object.assign({}, this.search)
      result = await RestApi.getData(licenseRegistrationServiceBaseUrl, ministryDealerDasbhoard, params)
      if (result.success) {
          this.totalDealer = result.data.total_dealer
          this.dealerList = result.data.regional_data
          this.regionalOfficeList = result.data.regionalOffices
          this.loadData = false
          this.show = true
          this.loader = false
      } else {
          this.loadData = false
          this.loader = false
      }
    }
  }
}
</script>
<style lang="scss">
.dealer-count-card{
      background-color: #F6FFF5;
      border: 1px solid #279660;
      position: relative;
      color: #222;
      padding: 1rem;
      border-radius: 10px;
      overflow: hidden;
      text-align: center;
      margin-bottom: 1rem;
  .bg-svg{
    position: absolute;
      top: 0;
      left: 0;
      height: -webkit-fill-available;
      width: auto;
  }
}
  .mc-report-card {
    position: relative;
    margin-bottom: 10px;
    border-radius: var(--border-radius-md);
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    color: rgba(255, 255, 255, .3);
    font-size: 3.6rem;
  }
</style>
