<template>
  <div class="section-wrapper">
    <b-card class="trade_fire_wrapper">
      <b-row>
        <div class="sec_title">
          <div class="text">
            <span>{{ currentLocale == 'en' ? 'Allocation Diagram' : 'বরাদ্দ চিত্র' }}</span>
          </div>
          <div class="line"></div>
        </div>
        <b-col sm="4">
          <b-form-group label-for="fiscal_year_id">
            <template v-slot:label>
              {{ $t('tradeFairConfig.fiscal_year') }}
            </template>
            <b-form-select plain id="fiscal_year_id" :options="fiscalYearList" v-model="search.fiscal_year_id">
              <template v-slot:first>
                <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label-for="allotment_count">
            <template v-slot:label>
              {{$t('allocation_management.allotment_count')}}
            </template>
            <b-form-select plain id="allotment_count" :options="allotmentCount" v-model="search.allotment_count" :disabled="!loadAllotment">
              <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3" class="mt-3">
          <b-button size="sm" variant="primary" class="mb-2" @click="searchData">
            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
          </b-button>
        </b-col>
      </b-row>
      <itf-loading v-if="!dataLoad" :step="1" />
      <b-row class="product-card-wrapper" v-else>
        <template v-if="categories.length > 0">
          <div class="sec_title">
            <div class="text">
              <span>{{ currentLocale == 'en' ? 'Entire Bangladesh Image' : 'সমগ্র বাংলাদেশ চিত্র' }}</span>
            </div>
            <div class="line"></div>
          </div>
            <b-col v-for="(category, index) in categories" :key="index" class="product-card-col mt-3 mt-sm-0 mb-2" sm="3">
              <div class="info-card" :class="category.org_id">
                <div class="content">
                  <p>{{ currentLocale === 'bn' ? category.item_category_bn : category.item_category_en }}</p>
                  <h5>{{ $n(category.allocated_quantity, { useGrouping: false}) }}</h5>
                  <!-- <h5>{{ getKgToQuintal(category) }}</h5> -->
                  <small>({{ category.id !== 3 ? $t('globalTrans.kg') : $t('allocation_dc_office.liter')
                    }})</small>
                </div>
                <div class="img">
                  <img :src="baseUrl + 'storage' + category.image" class="img-fluid" alt="image">
                </div>
              </div>
            </b-col>
          <div class="sec_title">
            <div class="text">
              <span>{{ currentLocale == 'en' ? 'Division Wise Image' : 'বিভাগ ভিত্তিক চিত্র' }}</span>
            </div>
            <div class="line"></div>
          </div>
          <b-col v-for="(allocation, divisionId) in divisions" :key="divisionId" class="bdr mt-3 mt-sm-0 mb-2" sm="3">
            <table height="250px;" border="1" width="100%" class="table-striped">
              <thead class="bg-info">
                <tr>
                  <td class="text-center text-white p-2" colspan="2">{{ getDivisionName(divisionId) }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(quantity, index) in allocation" :key="index">
                  <td class="pl-2"><b>{{ getItemCategoryName(index) }}</b> </td>
                  <td class="text-right pr-2"><b>{{ parseInt(index) !== 3 ? $n(quantity, { useGrouping: false}) :
                      $n(quantity, {useGrouping: false}) }}</b> <small>({{ parseInt(index) !== 3 ?
                      $t('globalTrans.kg') : $t('allocation_dc_office.liter') }})</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </template>
        <template v-else>
          <h5 class="text-center mt-4 text-danger">{{ $t('globalTrans.noDataFound') }}</h5>
        </template>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import itfLoading from '@/modules/trade-fair-service/dashboard/loading/itf_loading'

export default {
  props: [],
  components: {
    itfLoading
  },
  data () {
    return {
      selected_date: [],
      baseUrl: licenseRegistrationServiceBaseUrl,
      search: {
          fiscal_year_id: 0,
          allotment_count: 0
      },
      allotmentCount: [],
      categories: [],
      divisions: [],
      dataLoad: false,
      loadAllotment: false
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    }
  },
  created () {
    this.search.fiscal_year_id = this.currentFiscalYearId
    this.loadData()
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAllotmentCount()
        }
    },
    'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAllotmentCount()
        }
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    getDivisionName (Id) {
      const Obj = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === parseInt(Id))
      if (typeof Obj !== 'undefined') {
        return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
      } else {
        return ''
      }
    },
    getItemCategoryName (itemCategoryId) {
        const cateObj = this.categories.find(item => item.id === parseInt(itemCategoryId))
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.item_category_bn
            } else {
                return cateObj.item_category_en
            }
        }
    },
    getKgToQuintal (category) {
        let quantity = 0

        if (category.id !== 3) {
          quantity = parseFloat(category.allocated_quantity) / 100
        } else {
          quantity = category.allocated_quantity
        }

        return this.$n(quantity, { useGrouping: false })
    },
    async getAllotmentCount () {
        this.loadAllotment = false
        const params = Object.assign({}, this.search)
        const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, '/ministry/allotment-count', params)
        if (result.success) {
            const allotments = result.data
            const counts = this.$store.state.commonObj.gradeList.map(item => {
              if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
              } else {
                return { value: item.value, text: item.text }
              }
            })

            this.allotmentCount = counts.filter(item => allotments.includes(item.value))

            this.loadAllotment = true
        }
    },
    async loadData () {
        this.dataLoad = false
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const params = Object.assign({}, this.search)
        const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, '/ministry/allocation-dashbaord', params)
        if (result.success) {
            this.categories = result.data.categories
            this.divisions = result.data.divisions

            // Iterate over divisions to calculate missing categories
            for (const divisionId in this.divisions) {
                if (Object.hasOwnProperty.call(this.divisions, divisionId)) {
                    const allocation = this.divisions[divisionId]

                    // Iterate over categories to check for missing categories
                    this.categories.forEach(category => {
                        if (!Object.hasOwnProperty.call(allocation, category.id)) {
                            this.$set(allocation, category.id, 0)
                        }
                    })
                }
            }

            this.dataLoad = true
        } else {
          this.categories = []
          this.divisions = []
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>

<style lang="scss">
.b-calendar .b-calendar-inner .b-calendar-grid .b-calendar-grid-help {
    display: none !important;
}
.b-calendar .b-calendar-inner .b-calendar-nav {
    display: none !important;
}

.trade_fire_wrapper .info-card {
  border-radius: 1rem;
  color: #214162;
  padding: 1rem;
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade_fire_wrapper .info-card .content p {
  font-size: 20px;
}

.product-card-wrapper{
  .product-card-col{
    &:nth-child(1){
      .info-card {
        color: #237dd3;
        border: 1px solid #237dd3;
        background: #D2E8FD;
      }
    }
    &:nth-child(2){
      .info-card {
        color: #875c1c;
        border: 1px solid #875c1c;
        background: #FEF5CC;
      }
    }
    &:nth-child(3){
      .info-card {
        color: #04637e;
        border: 1px solid #04637e;
        background: #D0F2FC;
      }
    }
    &:nth-child(4){
      .info-card {
        color: #f14b1f;
        border: 1px solid #f14b1f;
        background: #FEE7D7;
      }
    }
    &:nth-child(5){
      .info-card {
        color: #81d810;
        border: 1px solid #81d810;
        background: #ecffd4;
      }
    }
    &:nth-child(6){
      .info-card {
        color: #100cdf;
        border: 1px solid #100cdf;
        background: #d4d3ff;
      }
    }
    &:nth-child(7){
      .info-card {
        color: #10AC85;
        border: 1px solid #10AC85;
        background: #d8fff5;
      }
    }
    &:nth-child(8){
      .info-card {
        color: #1579eb;
        border: 1px solid #1579eb;
        background: #d9ebff;
      }
    }
    &:nth-child(9){
      .info-card {
        color: #4315eb;
        border: 1px solid #4315eb;
        background: #e3dbff;
      }
    }
    &:nth-child(10){
      .info-card {
        color: #a715eb;
        border: 1px solid #a715eb;
        background: #f4dbff;
      }
    }
    &:nth-child(11){
      .info-card {
        color: #10AC85;
        border: 1px solid #10AC85;
        background: #d8fff5;
      }
    }
    &:nth-child(12){
      .info-card {
        color: #237dd3;
        border: 1px solid #237dd3;
        background: #D2E8FD;
      }
    }
  }
}

.trade_fire_wrapper .info-card  .img {
   width: 80px;
   height: 80px;
   overflow: hidden;
   border-radius: 40px;
   border: 2px solid;
   background-color: #fff;
   box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

   img {
     margin: 50% auto;
     transform: translateY(-50%);
   }
 }
.upcoming-fair{
  background: url(../../../../../assets/images/fair-card-bg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
}
.upcoming-fair .icon img{
  width: 100px;
}
.upcoming-fair .content{
  padding: 2rem;
  width: 100%;
  color: #fff;
  text-align: center;
}
.trade_fire_participant .info-card {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  padding: .5rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}
.trade_fire_participant .info-card.applicant .icon{
  background: #48B5E4;
}
.trade_fire_participant .info-card.approved .icon{
  background: #21C228;
}
.trade_fire_participant .info-card.peinding .icon{
  background: #C96BE5;
}
.trade_fire_participant .info-card.reject .icon{
  background: #F8576A;
}
.trade_fire_participant .info-card.paid .icon{
  background: #6349F8;
}
.trade_fire_participant .info-card.unpaid .icon{
  background: #E79B55;
}
.trade_fire_participant .info-card .atended .icon{
  background: #55A6F8;
}
.trade_fire_participant .info-card .icon{
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  font-size: 1.5rem;
  margin-right: 0.6rem;
  margin-top: -3rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
  justify-content: center;
  align-items: center;
}
</style>
